body {
  margin: 0;
  font-family: -apple-system, "NTR", Faktum Regular, "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Faktum Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Faktum Regular"), url("./font/Rene Bieder - Faktum Test Regular.woff") format("woff");
}

@font-face {
  font-family: "Faktum Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Faktum Bold"), url("./font/Rene Bieder - Faktum Test Bold.woff") format("woff");
}
