.app-main-wrapper {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .dashboard-wrapper {
    display: flex;
    flex-direction: column;
  }
}
