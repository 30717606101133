.loader {
  width: 10vw;
  display: block;
  overflow: hidden;
  text-align: center;
  opacity: 1;
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .loader {
    width: 30%;
  }
}
