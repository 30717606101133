.side_drawer {
  strong {
    font-weight: 600;
  }
  .side_header {
    padding: 2rem;
    background: #4e5462;
    color: #fff;
    .project_id {
      opacity: 0.6;
      font: 600 0.875rem/1.45 Faktum, Open Sans, Helvetica Neue, arial, sans-serif;
    }
    .project_title {
      font: 600 2rem/1.15 Faktum, Open Sans, Helvetica Neue, arial, sans-serif;
    }
    .project_address {
      font: 400 1rem/1.5 Faktum, Open Sans, Helvetica Neue, arial, sans-serif;
    }
    .print {
      font-weight: 400;
      font-size: 16px;
      label {
        margin-left: 5px;
      }
    }
  }

  .map-button {
    outline: none;
    background: linear-gradient(0deg, #6576ff 10%, transparent 0);
    color: white;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 900;
    line-height: 1;
    text-decoration: none;
    padding: 1px 6px;
    border: none;

    &:hover {
      background: linear-gradient(0deg, #6576ff 30%, transparent 0);
    }
  }

  .side_sub_header {
    padding: 1.5rem 2rem;
    color: #141b4d;
    font-size: 0.75rem;
  }

  .sub-menu-divider {
    &::before {
      border-top-color: #4e5462;
    }

    &::after {
      border-top-color: #4e5462;
    }
    .description {
      color: #364a63;
      font-size: 22px;
      font-weight: bold;
    }
  }

  .link-sidebar {
    color: #1890ff;

    &:hover {
      color: #063cdd;
    }
  }
}

.side_nav_container {
  .asideContent {
    position: relative;
    padding: 0 2rem;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
}

.ant-drawer-content {
  overflow: unset !important;
}
.ant-drawer-content-wrapper {
  width: 45vw !important;
}
.ant-drawer-body {
  padding: 0 !important;
  overflow: scroll !important;
}

.ant-drawer-open {
  .ant-drawer-close {
    position: fixed !important;
    right: 45vw !important;
    color: white !important;
    font-weight: 600 !important;
    outline: none !important;
  }
}
