.add-new-project-button {
  border: none;
  background-color: transparent;
  outline: none;
  color: white;
  display: flex;
  align-items: center;
  padding-top: 8px;

  &:focus {
    outline: none;
  }
}
