@import "~antd/dist/antd.css";
html {
  height: 100%;
}

body {
  font-family: "DM Sans", sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.65;
  color: #526484;
  text-align: left;
  /* background: #141B4D; */
}

strong {
  font-weight: 500;
}

a {
  color: #9d72ff;
  text-decoration: none;
  background-color: transparent;
}

.wrapper {
  text-align: center;
  height: 100%;
}

/* .primary-menu {
} */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: Nunito;
  font-weight: 700;
  line-height: 1.1;
  color: #364a63;
}

.btn-primary {
  color: #fff;
  background-color: #854fff;
  border-color: #854fff;
}

h3 {
  font: 600 1.5rem/1.2 Faktum, Open Sans, Helvetica Neue, arial, sans-serif;
}

span {
  font: 400 0.875rem/1.45 Faktum, Open Sans, Helvetica Neue, arial, sans-serif;
}

.medium {
  padding: 0.5em 1em;
  font: 600 1rem/1.5 Faktum, Open Sans, Helvetica Neue, arial, sans-serif;
}
